import React from 'react'
import Layout from '../components/layout'
import { Button } from '@trexity/common-client/lib/components'
// import { Link, navigate } from 'gatsby'

export default function Drivers () {
  const [videoVisible, setVideoVisible] = React.useState(false)

  return (
    <Layout type='driver' location='/drivers/'>
      <section>
        <div className='row equal align-center'>
          <div className='col'>
            <div className="inner">
              <h1>Simply download and drive.</h1>
              <p className='lede mb'>Accept routes, deliver, and get paid. Just apply below on your app of choice.</p>
              <div className="app-store" style={{ marginBottom: 15 }}>
                <a href="https://apps.apple.com/ca/app/trexity-driver/id1479475710"><img src="/img/store-apple.png" alt="Download on the App Store" /></a>
                <a href="https://play.google.com/store/apps/details?id=com.trexity.drivermobile"><img src="/img/store-google.png" alt="Get it on Google Play" /></a>
              </div>
              <p className='mice'>Must be 18+ and eligible to work in Canada</p>
            </div>
          </div>
          <div className='col'>
            <div className="inner image">
              <img src="/img/driver-hero.jpg" alt="Driver shown with earnings" />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='row align-center justify-center'>
          <div className='col w75'>
            <div className="inner text-left d-text-center">
              <h2>How it works</h2>
              <p className='mb'>With smart bundled deliveries, spend more time earning and less time waiting at storefronts. Our driver app shows you everything you need to efficiently complete delivery routes.</p>
              <ol className='equal-steps'>
                <li className='step'>
                  <div>
                    <div className='tile'><i className='material-icons brand-driver'>settings_ethernet</i></div>
                    <span className='context'>Go online</span>
                  </div>
                </li>
                <li className='step'>
                  <div>
                    <div className='tile'><i className='material-icons brand-driver'>notifications</i></div>
                    <span className='context'>Get notification</span>
                  </div>
                </li>
                <li className='step'>
                  <div>
                    <div className='tile'><i className='material-icons brand-driver'>store</i></div>
                    <span className='context'>Pick up</span>
                  </div>
                </li>
                <li className='step'>
                  <div>
                    <div className='tile'><i className='material-icons brand-driver'>drive_eta</i></div>
                    <span className='context'>Deliver</span>
                  </div>
                </li>
                <li className='step'>
                  <div>
                    <div className='tile end'><i className='material-icons brand-driver'>attach_money</i></div>
                    <span className='context'>Get paid</span>
                  </div>
                </li>
              </ol>
              <a
                className='button-link button-link-brand'
                onClick={() => { setVideoVisible(!videoVisible) }}
              >
                {!videoVisible ? 'Watch Trexity Overview' : 'Close video'}
              </a>
              {videoVisible ? (
                <React.Fragment>
                  <div className='responsive-video mt mb'>
                    <iframe
                      src="https://www.youtube.com/embed/e02evQNR-U0"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                  <div className='row justify-center align-center' style={{ margin: 30 }}>
                    <div className='col'>
                      <p>
                        Watch in:
                        <a href="https://www.youtube.com/watch?v=CWh-TSf3S4M" target="_blank" rel="noreferrer" style={{ margin: 5 }}>French</a>
                        <a href="https://www.youtube.com/watch?v=pW7LJW8XsRE" target="_blank" rel="noreferrer" style={{ margin: 5 }}>Punjabi</a>
                        <a href="https://www.youtube.com/watch?v=qQE6TZtUjoY" target="_blank" rel="noreferrer" style={{ margin: 5 }}>Arabic</a>
                      </p>
                    </div>
                  </div>
                </React.Fragment>
              ) : null}
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='row equal reverse align-center'>
          <div className='col'>
            <div className="inner image">
              <img src="/img/driver-toast.jpg" alt="sample notification" />
            </div>
          </div>
          <div className='col'>
            <div className="inner">
              <h2>Great flexibility</h2>
              <p>When you’re online, we’ll send you notifications for nearby deliveries with all the information you need—you can choose to accept them or not. Earn when you want to.</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='row equal align-center'>
          <div className='col'>
            <div className="inner">
              <h2>We’re with you</h2>
              <p className='mb'>Our in house driver support team is human, just like you, and they’re ready to answer any questions or solve any issues. We won’t leave you at the door.</p>
              <ol>
                <li className='row preserve align-center' style={{ marginBottom: 15 }}>
                  <i className='material-icons brand-driver' style={{ marginRight: 10 }}>check_circle</i>
                  Getting started
                </li>
                <li className='row preserve align-center' style={{ marginBottom: 15 }}>
                  <i className='material-icons brand-driver' style={{ marginRight: 10 }}>check_circle</i>
                  In-flight assistance
                </li>
                <li className='row preserve align-center'>
                  <i className='material-icons brand-driver' style={{ marginRight: 10 }}>check_circle</i>
                  Real-time support
                </li>
              </ol>
            </div>
          </div>
          <div className='col'>
            <div className="inner image">
              <img src="/img/driver-support.jpg" alt="sample support conversation" />
            </div>
          </div>
        </div>
      </section>

      <section className='well callout'>
        <div className='row align-center justify-center' style={{ minHeight: 400 }}>
          <div className='col w75 text-center'>
            <div className="inner">
              <h2>Be local, help local</h2>
              <p className='mb'>Safely drive products for passionate local merchants and enthusiastic customers. By supporting local business and their customers, you’re helping grow a thriving community.</p>
              <p className='lede'>Download the app to get started.</p>
              <div className="app-store">
                <a href="https://apps.apple.com/ca/app/trexity-driver/id1479475710"><img src="/img/store-apple.png" alt="Download on the App Store" /></a>
                <a href="https://play.google.com/store/apps/details?id=com.trexity.drivermobile"><img src="/img/store-google.png" alt="Get it on Google Play" /></a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
